import React,{useState, Component} from 'react';
import Axios from 'axios';
import './App.css';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import { MD5 } from 'crypto-js';
import Modal from 'react-awesome-modal';
import IframeComponent from './IframeComponent';
import Querystring from 'qs';
import { useEffect } from 'react';


/*const express = require("express");
const app = express();
app.use(function(req, res, next) {
  res.header("Access-Control-Allow-Origin", "https://test-global.paytriot.co.uk/"); // update to match the domain you will make the request from
  res.header("Access-Control-Allow-Headers", "Origin, X-Requested-With, Content-Type, Accept");
  next();
});

app.get('/', function(req, res, next) {
  // Handle the get for this route
});

app.post('/', function(req, res, next) {
 // Handle the post for this route
}); */

const html = ''




function App() {

  const windowUrl = window.location.search;
const params = new URLSearchParams(windowUrl);
console.log(params.get);
//var encodedStringBtoA = atob(params);
//console.log(encodedStringBtoA)


  //axios.defaults.baseURL="https://pay-global.paytriot.co.uk/";
  //axios.defaults.headers.common['Authorization'] = "01d74cd8-24cf-48e5-a78a-f97a3a43ce26";
  axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
  axios.defaults.headers.post['Cookie'] = "JSESSIONID=FDF24D1F1A7028AEE785786722ADE63E; TS012a9de9=016f07117d06ee7ec2fbf6eea50c1ffce8c32befdca28154e8857750864ba70bf5868f3f27450b649a10c439318854c5379f55ad24e6a16b5145555d8956804355991b8f9d; TS016d4b8b=016f07117d19b2eac65b4eb236689542face3a11afa28154e8857750864ba70bf5868f3f27eb7328de7fa7eaff3cc6cbde5f4850e1";

  const [firstname, setfirstname] = useState(params.get('firstname'));
  const [lastname, setlastname] = useState(params.get('lastname'));
  const [email, setemail] = useState(params.get('email'));
  const [phone, setphone] = useState(params.get('phone'));
  const [address, setaddress] = useState(params.get('address'));
  const [city, setcity] = useState(params.get('city'));
  const [state, setstate] = useState(params.get('state'));
  const [zipcode, setzipcode] = useState(params.get('zip'));
  const [country, setcountry] = useState(params.get('country'));
  const [payby, setpayby] = useState("card");
  const [cardname, setcardname] = useState("Cardholder name");
  const [cardnumber, setcardnumber] = useState("4711100000000000");
  const [ccexpmonth, setccexpmonth] = useState("12");
  const [cardtype, setcardtype] = useState("VISA");
  const [ccexpyear, setccexpyear] = useState("2022");
  const [cvv, setcvv] = useState("123");
  const [currency, setcurrency] = useState(params.get('currency'));
  const [amount, setamount] = useState(params.get('amount'));
  const [orderno, setorderno] = useState(params.get('orderid'));

  const [modal, setModal] = useState(false);
  let api_data = { "foo": "sample", "bar": "sample" }

  let iframe = '';


  function myFunction(dataa) {
    alert( JSON.stringify(dataa.tradeMsg) + "\n" + JSON.stringify(dataa));
    return (
      <div>
        <IframeComponent src= {iframe} height="100%" width="95%"/>
      </div>
    );
  }

  

  function Iframes() {




    


  }

  function createMarkup() {



  
}

function MyComponent() {
  const [html, setHTML] = useState({__html: ""});

  //let absolute = new URL(relative, frame.contentWindow.location.href)
    
  useEffect(() => {
    async function createMarkup() {

      let response;
     /* response = await fetch(`https://foriseu-vbv.mycardplace.com/vbv/payerAuthentication`,{
        "method": "POST",
        "headers": {
          "content-type": "application/x-www-form-urlencoded",
          'Access-Control-Allow-Origin':'*'
        },
        "body":{
          PaReq: 'eJxVUk1vwjAM/SsVp+0w0oRSWmQidXTSOPAhPnYPiRnVoC1pymC/fgmUwQ6R/GzHfn42LLcaMV2grDVyGGNViU/0MjVohR3KmFJsLSIVtjjMkjkeOBxRV1mRc9r22wzIDdqvWm5FbjgIeXgdTXjAaNz1gTQQ9qhHKe/5cY/SEMgVQi72yIeFLr2lFkfcedOV9/S2mnsfo0XyDOQSB1nUudFnHjH78wag1ju+Naas+oRI8yKktPTbpS4I5gSICwO585rVzqpsuVOm+DhNvh/eaZp+BZNU+pOf8QCIywAlDHLmM+bHPvNo2GesH3SAXPwg9o4Hd1ytFm7QqwNK1ye5AuoCjw6wQmvM5ZnHvcjOckOAp7LI0WZYUf9sUFhJvsTKeGXD3rZ3PiD3cYbvTnRprJ6OLQu69nWszBshA9zEUdhV62DjVnFJcr0yKyALqX9p5gAQV4Y0WybNJVjr34X8Ak1stqw=',
          TermUrl: 'https://paytriot-global.paytriot.co.uk/&acs=1'
        }
      })
       const backendHtmlString = await response.text()

       console.log(backendHtmlString)
        return {__html: backendHtmlString};*/
     

      const cards_data = {
          PaReq: 'eJxVUk1vwjAM/SsVp+0w0oRSWmQidXTSOPAhPnYPiRnVoC1pymC/fgmUwQ6R/GzHfn42LLcaMV2grDVyGGNViU/0MjVohR3KmFJsLSIVtjjMkjkeOBxRV1mRc9r22wzIDdqvWm5FbjgIeXgdTXjAaNz1gTQQ9qhHKe/5cY/SEMgVQi72yIeFLr2lFkfcedOV9/S2mnsfo0XyDOQSB1nUudFnHjH78wag1ju+Naas+oRI8yKktPTbpS4I5gSICwO585rVzqpsuVOm+DhNvh/eaZp+BZNU+pOf8QCIywAlDHLmM+bHPvNo2GesH3SAXPwg9o4Hd1ytFm7QqwNK1ye5AuoCjw6wQmvM5ZnHvcjOckOAp7LI0WZYUf9sUFhJvsTKeGXD3rZ3PiD3cYbvTnRprJ6OLQu69nWszBshA9zEUdhV62DjVnFJcr0yKyALqX9p5gAQV4Y0WybNJVjr34X8Ak1stqw=',
          TermUrl: 'https://paytriot-global.paytriot.co.uk/&acs=1'
        }
      
        Axios.post('https://foriseu-vbv.mycardplace.com/vbv/payerAuthentication',Querystring.stringify(cards_data))
        .then(res=>{
          api_data = res.data;
          iframe = res.data;
          //console.log("Response",res.data)
          const backendHtmlString = res.data;
        //console.log(backendHtmlString)
        return {__html: backendHtmlString};
        })

     }
     createMarkup().then(result => setHTML(result));
  }, []);
  return <div dangerouslySetInnerHTML={createMarkup()} />;
}


 

  function submit(e){
 
    const carppay = {
      merId :"20220510",
      submerId : "202260",
      orderId:orderno,
      orderAmt:amount,
      channel:"w600",
      currency:currency,
      cardType:cardtype,
      cardNo:cardnumber,
      cardDate:ccexpyear + ccexpmonth,
      cardCvv:cvv,
      cardName:cardname,
      desc:"phone",
      attch:"100234",
      mob:phone,
      email:email,
      billFirstName: firstname,
      billLastName: lastname,
      billAddress: address,
      billCity: city,
      billState: state,
      billCountry: country,
      billZip: zipcode,
      ip:"123.123.123.123",
      notifyUrl:"https://api.notify.com/notify",
      nonceStr:"XLtc4dzQslfq9JyaTAN50hKeoj7ZDBUH",
      live_mode : true
  }
    const azul_paydata= {
      "MID": "234777",
      "apiKey":"c34cd4095f96df1b0459f31f1c004b7b6bbbae8ad42af845c246ce62b6d1127f",
      "submerId":"202292",
      "channel":"w600",
      "desc":"Phone",
      "attch":"123456",
      "nonceStr":"adhfbwdje8",
      "orderNo":orderno,
      "orderAmount":"100",
      "orderCurrency": 978,
      "referenceId":"12334",
      "address":"The Charter Building",
      "firstName": "Ken",
      "lastName":"Adams",
      "phone":"07860593303",
      "email" : "info@paytriot.co.uk",
      "zip":"UB83TB",
      "state":"Uxbridge",
      "city": "london",
      "country":826,
      "cardName":"ken Adams",
      "cardNo":"5521573047858233",
      "cvv":"170",
      "expMonth":"11",
      "expYear":"2022",
      "website":"www.google.com",
      "cardType":"VISA",
      "payBy":"card",
      "notifyUrl":"https://paytriot.com/callback.php",
      "redirectUrl":"https://paytriot.com",
      "ip":"123.123.123.123",
      "live_mode":true,
      "is_3DS": false
    }

    const headers_query = {
      //Authorization: testsecret,
      "content-Type": "application/json",
      'Access-Control-Allow-Origin':'*'
    };



    Axios
    .post('https://paytriot-global.paytriot.co.uk/payment/create_payment', JSON.stringify(azul_paydata),{
      headers: headers_query,
    })
    .then(function (response) {
      // handle success
      //console.log('responsedata->',response.data)
      let text = response.data;
      console.log(text);
      document.getElementById('homepage').innerHTML = text
      //res.json(response.data);
      //return;
    }) ;






 /* const azul_paydata= 
    {
      apiKey:"c34cd4095f96df1b0459f31f1c004b7b6bbbae8ad42af845c246ce62b6d1127f",
      merId : "1002022020",
      billLastName:lastname,
      billFirstName:firstname,
      email:email,
      mob:phone,
      billAddress:address,
      billCity:city,
      billState:state,
      billCountry:country,
      billZip:zipcode,
      orderAmt:amount,
      currency:currency,  
      cardType:cardtype,
      cardName:cardname,
      cardNo: cardnumber,
      cardCvv:cvv,
      expYear:ccexpyear,
      expMonth:ccexpmonth,
      orderId:orderno,
      ip:"192.168.12.14",
      redirectUrl:"https://paytriot.com",
      webhookUrl:"https://paytriot.com/callback.php",
      live_mode : true,
      is3DS : true 
    }*/


  //console.log(azul_paydata);


    e.preventDefault();

    /*    fetch("http://localhost:3000/payment/create_payment", {
      "method": "POST",
      "headers": {
        "content-type": "application/json",
      },
      "body": {
        "MID": "234777",
        "apiKey":"c34cd4095f96df1b0459f31f1c004b7b6bbbae8ad42af845c246ce62b6d1127f",
        "submerId":"202292",
        "channel":"w600",
        "desc":"Phone",
        "attch":"123456",
        "nonceStr":"adhfbwdje8",
        "orderNo":"1232991381111902",
        "orderAmount":"100",
        "orderCurrency": 978,
        "referenceId":"12334",
        "address":"The Charter Building",
        "firstName": "Ken",
        "lastName":"Adams",
        "phone":"07860593303",
        "email" : "info@paytriot.co.uk",
        "zip":"UB83TB",
        "state":"Uxbridge",
        "city": "london",
        "country":826,
        "cardName":"ken Adams",
        "cardNo":"5354568091448628",
        "cvv":"026",
        "expMonth":"12",
        "expYear":"2026",
        "website":"www.google.com",
        "cardType":"VISA",
        "payBy":"card",
        "notifyUrl":"https://paytriot.com/callback.php",
        "redirectUrl":"https://paytriot.com",
        "ip":"123.123.123.123",
        "live_mode":true,
        "is_3DS": false
      }
    })
    //.then(response => response.send())
    .then(response => {
      console.log(response.body)
      function IIframeResponse (){}
    })
    .catch(err => {
      console.log(err);
    }); */
  
  }
  return (
    <div className='centre' >
      <h1>Paytriot Payments </h1>
      <div className='row'> 
        <form >
          <div className='row1'>
          <label>OrderNumber:
              <input
                className='inp-fntend'
                name="OrderNumber"
                type="text"
                value={orderno}
                onChange= {(e) => setorderno(e.target.value)} />
            </label>
            <label>FirstName:
              <input
                className='inp-fntend'
                name="FirstName"
                type="text"
                value={firstname}
                onChange= {(e) => setfirstname(e.target.value)} />
            </label>
            <label>LastName:
              <input
                className='inp-fntend'
                name="LastName"
                type="text"
                value={lastname}
                onChange= {(e) => setlastname(e.target.value)} />
            </label>
            <label>Email:
              <input
                className='inp-fntend'
                name="email"
                type="text"
                value={email}
                onChange= {(e) => setemail(e.target.value)} />
            </label>
            <label>Phone:
              <input
                className='inp-fntend'
                name="Phone"
                type="text"
                value={phone}
                onChange= {(e) => setphone(e.target.value)} />
            </label>
            <label>Address:
              <input
                className='inp-fntend'
                name="address"
                type="text"
                value={address}
                onChange= {(e) => setaddress(e.target.value)} />
            </label>
            <label>City:
              <input
                className='inp-fntend'
                name="city"
                type="text"
                value={city}
                onChange= {(e) => setcity(e.target.value)} />
            </label>
            <label>State:
              <input
                className='inp-fntend'
                name="state"
                type="text"
                value={state}
                onChange= {(e) => setstate(e.target.value)} />
            </label>
            <label>Zipcode:
              <input
                className='inp-fntend'
                name="zipcode"
                type="text"
                value={zipcode}
                onChange= {(e) => setzipcode(e.target.value)} />
            </label>
            <label>Country:
              <input
                className='inp-fntend'
                name="country"
                type="text"
                value={country}
                onChange= {(e) => setcountry(e.target.value)} />
            </label>
          </div> 
          <div className='row1'> 
            <label>payby:
              <input
                className='inp-fntend'
                name="payby"
                type="text"
                value={payby}
                onChange= {(e) => setpayby(e.target.value)} />
            </label>
            <label>CardName:
              <input
                className='inp-fntend'
                name="cardname"
                type="text"
                value={cardname}
                onChange= {(e) => setcardname(e.target.value)} />
            </label>
            <label>CardNumber:
              <input
                className='inp-fntend'
                name="cardnumber"
                type="text"
                value={cardnumber}
                onChange= {(e) => setcardnumber(e.target.value)} />
            </label>
            <label>CardType:
              <input
                className='inp-fntend'
                name="cardtype"
                type="text"
                value={cardtype}
                onChange= {(e) => setcardtype(e.target.value)} />
            </label>
            <label>ExpMonth:
              <input
                className='inp-fntend'
                name="ccexpmonth"
                type="text"
                value={ccexpmonth}
                onChange= {(e) => setccexpmonth(e.target.value)} />
            </label>
            <label>ExpYear:
              <input
                className='inp-fntend'
                name="ccexpyear"
                type="text"
                value={ccexpyear}
                onChange= {(e) => setccexpyear(e.target.value)} />
            </label>
            <label>CVV:
              <input
                className='inp-fntend'
                name="cvv"
                type="text"
                value={cvv}
                onChange= {(e) => setcvv(e.target.value)} />
            </label>
            <label>Currency:
              <input
                className='inp-fntend'
                name="currency"
                type="text"
                value={currency}
                onChange= {(e) => setcurrency(e.target.value)} />
            </label>
            <label>Amount:
              <input
                className='inp-fntend'
                name="Amount"
                type="text"
                value={amount}
                onChange= {(e) => setamount(e.target.value)} />
            </label>
          </div>  
          
        </form>
        
        </div>
        <button className='btn' type='submit' onClick={submit} >Submit</button> 
        <div> 
          
        </div>
        <div id = "homepage">
        
      </div>
        


    </div>
    
  );
}

 /* constructor(props) {
    super(props);
    this.state = {
      firstname:"test",
      lastname:"test",
      email:"test@gmail.com",
      phone:"7845125623",
      address:"test",
      city:"test",
      state:"dl",
      zipcode: "4567",
      country:"AI",
      timestamp:"12010210",
      payby:"Visa",
      cardname: "test",
      Cardnumber: "4111111111111111",
      ccexpmonth:"01",
      ccexpyear: "34",
      cvv: "123",
      currency: "USD",
      amount: 15,
      postbackurl:"https://www.google.com/vt/redirect/",
      successurl:"https://www. google.com/vt/redirect/",
      failurl:"https://www. google.com/vt/redirect/",
      c1:null,
      c2:null,
      c3:null,
      tokencode:"cc9bba11e51208fff4af83d144296f14",
      ipaddress:"::1",
      cctokenid:null,
      istokenrequest:false

    }
  };*/

export default App;


